/* eslint-disable no-unused-vars */

import React, { useState , useEffect}  from "react";
import Resizer from "react-image-file-resizer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from 'prop-types';
import Cookies from "js-cookie";
import PhotoGallery from "./FotoCards/CardPhoto";
import { Axios } from "axios";
import "./Foro.css"



const NewPostForm = ({categoria, setNewPost ,category_title}) =>{

const [imgs,setImgs]=useState("")
const navigate = useNavigate();
const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    files.forEach((file) => {
      Resizer.imageFileResizer(
        file,
        1080, // max width
        1080, // max height
        "JPEG", // format
        70, // quality
        0, // rotation
        (uri) => {
          setImgs((prevImgs) => {
            const updatedImgs = [...prevImgs, uri].slice(-3); // Mantener solo las últimas 3 imágenes
            return updatedImgs;
          });
        },
        "base64"
      );
    });
  };
 const new_response ={}; 
 
const[newMesg,setNewMesg]=useState("")
const[newtitle,setNewTitl]=useState("")



 const upload_post = (newpost) => {
    axios
      .post("/api/newpost/", newpost, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        window.location.reload();

        

      })
      .catch((error) => console.log(error));
  };
  
  //  newpost={"user_id":int(current_user),"msg":data.msg,"title":data.tittle,"category":data.category,"images":data.imgs}
   
  const UploadPost=()=>{
  const images_str =imgs?imgs.join("<>"):"";
  new_response.images=images_str;
  new_response.msg=newMesg
  new_response.title=newtitle
  new_response.category=categoria
  console.log(new_response)
  upload_post(new_response)
  setNewPost(false)
  

  }

    return(

        <>
        
        <div className="postformnewpost" style={{maxWidth:"1350px", margin:"0 auto", minHeight:"200px", justifyContent:"center", alignContent:"center"}}>
                  <h5>Estás posteando en la categoría: {category_title}</h5>  
                  <p>Título para tu post</p>  
                  <input className="titlenewpost" onChange={(e) => setNewTitl(e.target.value)} />
                  <p>Escribe el mensaje:</p>  
                    <textarea
                    defaultValue={""}
                     onChange={(e) => setNewMesg(e.target.value)}
          maxLength="550"
          className={`caja-texto-profile`}
        ></textarea>
<p>Puedes subir 3 imagenes acá:</p>
               <label style={{color:"black"}} className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg inline-block">
        Subir Imagen
        <input
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          multiple
          className="hidden"
        />
      </label>
               <div className="flex gap-2 mt-4">
        {imgs&&imgs.map((img, index) => (
          <img style={{maxWidth:"15%"}}
            key={index}
             src={img}
            alt={`Uploaded ${index + 1}`}
            className="w-24 h-24 object-cover rounded-lg"
          />
          
        ))}
         
      </div>
      <div style={{justifyContent:"right", alignContent:"right",textAlign:"right"}}>
               <button className="buttonforo"onClick={()=>(UploadPost())}>Subir</button>
               <button className="buttonforo"onClick={()=>(setNewPost(false))}>Cancelar</button>
                {/* <button
              className="remove-btn"
              onClick={() => removePhoto(index)}
              aria-label="Remove photo"
            >
              &#10005;
            </button> */}
             </div>
                </div>

           </>
        
    )
}

NewPostForm.propTypes = {
  categoria: PropTypes.string,
  setNewPost: PropTypes.func,
  category_title:PropTypes.string,
   // name debe ser una cadena de texto y es obligatorio
     // age debe ser un número y es obligatorio
};


export default NewPostForm

