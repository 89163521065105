import React, { useMemo } from "react";
import "./carrusel.css";

const SlidingBanner = () => {
         const items =["Ansiedad","Depresión","Trauma","Autismo","Déficit Atencional","Trastorno Bipolar","Duelo","Violencia","LGBTI+","Identidad de género","Separación","Crianza","Parentalidad","Maltrato infantil","Trastornos Alimentarios",
        "Fobias","Trastorno Obsesivo Compulsivo","Esquizofrenia","Consumo de sustancias","Trastorno de Personalidad","Crisis de pánico", "Crisis existenciales","Adolescencia",
        "Imagen Corporal","Suicidio","Autolesiones","Fobia escolar","Habilidades sociales","Adicciones","Abuso Sexual","Terapia de parejas",
        "Estrés crónico","Estrés laboral","Crisis vitales","Dolor crónico","Problemas conductuales","Crisis relacional"]
 

  // 🔹 Memoizar la lista aleatoria de items para que no se regenere en cada render
  const shuffledItems = useMemo(() => {
    return [...items].sort(() => Math.random() - 0.5);
  }, []); // 🔹 Se calcula solo una vez, evitando reordenamientos innecesarios

  const splitItems = (arr) => {
    const rowCount = 4;
    const rowSize = Math.floor(arr.length / rowCount);
    const remainder = arr.length % rowCount;
    const rows = [];
    let startIndex = 0;

    for (let i = 0; i < rowCount; i++) {
      const extraItem = i < remainder ? 1 : 0;
      rows.push(arr.slice(startIndex, startIndex + rowSize + extraItem));
      startIndex += rowSize + extraItem;
    }

    return rows;
  };

  const [row1, row2, row3, row4] = splitItems(shuffledItems);

  return (
    <div style={{ maxWidth: "1250px", margin: "0 auto", marginTop: "2%" }}>
      <div className="sliding-banner">
        {[row1, row2, row3, row4].map((row, rowIndex) => (
          <div className="rowslide" key={rowIndex}>
            {[...row, ...row].map((item, index) => (
              <div className="sliding-item" key={`${rowIndex}-${index}`}>
                {item}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SlidingBanner;
