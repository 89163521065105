import React from "react";
import NavBar from "../navbar/navbar";
import "./FAQ.css";
import Contact from "../contact/Contact";
import Fotr from "../Footer/footer";

const Faq = () => {
  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />

      <section
        className="container-fluid"
        style={{
          width: "fit-content",
          background: "white",
          height: "100hv",
        }}
      >
        <div className="row row-cols-1 no-gutters">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 FAQ">
            <h1 className="fuenteh2">Preguntas Frecuentes</h1>

            <h2 className="fuenteh2">¿Cómo funciona vincular.se?</h2>

            <article className="fuente2">
              <p>
                vincular.se cuenta con una base diversa de profesionales, los
                cuales se diferencian unos a otros en: estilo de terapia y
                enfoque, experiencia, preferencias, especialización, etc...
              </p>
              <p>
                Para poder generar conexiones paciente-profesional utilizamos la
                información compartida por ambos, la analizamos con nuestro
                algoritmo y buscaremos los psicólogos o psicólogas con mayor
                afinidad para el usuario consultor a tu perfil.
              </p>
                <p>
                Consideramos problemáticas del paciente, enofques de terapia, experiencia del profesional, entre otras cosas
              </p>
              <p>
                Creemos que la decisión final siempre la debe tener el usuario
                es por eso que el algoritmo, siempre que sea posible, va a
                presentar al menos cinco opciones para poder elegir.
              </p>
            </article>
            <h2 className="fuenteh2">¿Es 100% efectivo?</h2>
            <article className="fuente2">
              <p>
                No, lamentablemente no podemos asegurar que el profesional
                elegido por el usuario sea el más idóneo, pues, el vinculo y el
                espacio generado en terapia es algo mucho más complejo que un análisis de datos.
              </p>
              <p>
                Las buenas noticias son que mientras más usuarios tenemos y más
                usuarios nos dan su retroalimentación, hay más probabilidades de
                que el profesional elegido sea indicado para el usuario. Es por
                eso que !tu ayuda nos sirve para ayudar a los demás!
              </p>
            </article>

            <h2 className="fuenteh2">
              ¿Cómo puedo vincularme con profesionales?
            </h2>
            <article className="fuente2">
              <p>
                Si estás dudando como realizar este proceso de la mejor forma
                puedes dar click en el botón de abajo para ver algunos ejemplos,
                esperamos que te sean útiles.
              </p>
            </article>
            <div style={{ textAlign: "center" }}>
              <a href="/ayuda">
                <button className="boton-ovalado">
                  <b>Ver como </b>
                </button>
              </a>
            </div>
            <h5 className="mt-4">
              Si no hay más dudas... entonces ya puedes ir a buscar
            </h5>

            <div className="mt-4" style={{ textAlign: "center" }}>
              <a href="/?bts=Ok">
                <button className="boton-ovalado">
                  <b>Ir a buscar </b>
                </button>
              </a>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </section>
      <Contact />
      <Fotr />
    </div>
  );
};

export default Faq;
