import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom"; 




export function WorkspaceNav({ selectedTab, setSelectedTab, profileLink }) {
  WorkspaceNav.propTypes = {
    selectedTab: PropTypes.string,
    setSelectedTab: PropTypes.func,
    profileLink: PropTypes.string,
  };
  
  const [expandedmobilebutton, setExpandedmobilebutton] = useState(false);
  const eMobile = () => {
    setExpandedmobilebutton(!expandedmobilebutton);
  };

  const setSelectedColor = (tab) => {
    return { color: selectedTab === tab ? "#FFC97D" : "white" };
  };

  return (
    <div className="col-lg-2">
      <div className="izqwk">
        <div className="buttonmobile">
          <a onClick={eMobile}>
            <i className="fa-solid fa-bars fawks"></i>
          </a>
        </div>
      <Link to="" onClick={() => {
              
              setSelectedTab("home");
              setExpandedmobilebutton(false);
            }}>
           <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
            <i
              className="fa-solid fa-house fawks"
              style={setSelectedColor("home")}
            ></i>
            <p className="txtwk" style={setSelectedColor("home")}>
              Home
            </p>
         </div></Link>
        
        <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
          <a className="linkwkspc" href={`/ps/${profileLink}`}>
            {" "}
            <i className="fa-solid fa-user fawks"></i>
            <p className="txtwk">Perfil</p>
          </a>
        </div>
       <Link to="agenda" onClick={() => {
              
              setSelectedTab("agenda");
               
              setExpandedmobilebutton(false);
            }}> <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
        
            <i
              className="fa-solid fa-calendar fawks"
              style={setSelectedColor("agenda")}
            ></i>
            <p className="txtwk" style={setSelectedColor("agenda")}>
              Agenda
            </p>
          
          
        </div></Link>
        <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
          <a
            className="linkwkspc"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("pacientes");
              setExpandedmobilebutton(false);
            }}
          >
            <i
              className="fa-solid fa-address-card fawks"
              style={setSelectedColor("pacientes")}
            ></i>
            <p className="txtwk" style={setSelectedColor("pacientes")}>
              Pacientes
            </p>
          </a>
        </div>
         
       <Link  to="foro"  onClick={() => {
           
              setSelectedTab("foro");
              setExpandedmobilebutton(false);
            }}>
        <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
         
                  <i
              className="fa-solid fa-user-group fawks"
              style={setSelectedColor("pacientes")}
            ></i>
            <p
              className="txtwk"
              style={setSelectedColor("foro")}
            >
             Comunidad
            </p>
          
        </div></Link>
        <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
          <i className="fa-solid fa-envelope fawks"></i>
          <p className="txtwk">
            Mensajes{" "}
            <b
              style={{
                fontSize: "10px",
                alignSelf: "center",
                color: "#FFC97D",
              }}
            >
              {" "}
              próximamente
            </b>
          </p>
        </div>
        <Link to="ayuda" onClick={() => {
              
              setSelectedTab("help");
              setExpandedmobilebutton(false);
            }}>
         <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
      
            {" "}
            <i
              className="fa-solid fa-circle-info fawks"
              style={setSelectedColor("help")}
            ></i>
            <p className="txtwk" style={setSelectedColor("help")}>
              Ayuda
            </p>
          
        </div>
        </Link>

<Link to="settings" onClick={() => {
              
              setSelectedTab("config");
              setExpandedmobilebutton(false);
            }}>
        <div
          className={`optionshome ${expandedmobilebutton ? "expandwk" : ""}`}
        >
     
            {" "}
            <i
              className="fa-solid fa-gear fawks"
              style={setSelectedColor("config")}
            ></i>
            <p className="txtwk" style={setSelectedColor("config")}>
              Configuración
            </p>
          
        </div></Link>
      </div>
    </div>
  );
}
