import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Image from "./img/networking.png";
import NavBar from "./navbar/navbar";
import Contact from "./contact/Contact";
import Fotr from "./Footer/footer";
import Modal_ from "./Modals/Modal_";
import FormHora from "./agendamiento/formhora.js";
import { HomeSectionA } from "./Homesections/sectiona";
import { UserContext } from "./login/login-context";
import { BeatLoader } from 'react-spinners';

import background from "./img/BackDekstop.jpg";
import backgroundmob from "./img/BackMob.jpg";
import { AlertTriangle } from "lucide-react";

import SlidingBanner from "./Homesections/sectionb2.js";
import { ExplainText } from "./Homesections/TextOverPhoto.js";

const Home = () => {
  const [isExpanded, setisExpanded] = useState(false);
  const [loading, setLoading] = useState(true);

  const queryParameters = new URLSearchParams(window.location.search);
  const campaignId = queryParameters.get("campaign");
  const test_match = queryParameters.get("matcher");
  

  const expandir = () => {
    setisExpanded(true);
  };
  const [txt, setTXT] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [respuestaT, setRespuestaT] = useState({});

  const navigate = useNavigate();
  const handleModalAgendar = (valuemodal) => {
    setModalAgendar(valuemodal);
  };
  const [modalagendar, setModalAgendar] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  useEffect(() => {
    if (user && user.logged_in) {
      if (user.is_admin) {
        navigate("/dashboard");
      } else {
        navigate("/home");
      }
    }
  }, [user]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

    const [showPopup, setShowPopup] = useState(false);
  const handleMsgUser=()=>{
if( txt.length>0){

  if(test_match==="test"){enviarFormulario(),setLoading(false)}
                    else{handleModalAgendar(true),ClickSend()}
     
    } else {
      ClickSend();
        setShowPopup(true);
      setTimeout(() => setShowPopup(false), 3000);
      
    }
}
                   
  
  useEffect(() => {
    if (campaignId != undefined) {
      axios
        .post("/api/campaign", {
          campaign_id: campaignId,
          timestamp: new Date(),
        })
        .then(() => {})
        .catch(() => {});
    }
  }, [campaignId]);

  const ClickSend = () => {
    // Datos a enviar en la solicitud POST
    const datetime = new Date();
    axios
      .post("/api/clickbusqueda", datetime)
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log("Respuesta:", response.data);
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error("Error:", error);
      });
  };

  const enviarFormulario = () => {
    // Datos a enviar en la solicitud POST
    const formData = {
      message: txt,
    };
    axios
      .post("/api/matcher", formData)
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log("Respuesta:", response.data);
        setRespuestaT(response.data);
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error("Error:", error);
      });
  };


const texts = ["¿Qué te gustaría resolver?", "¿Tienes alguna problemática?", "¿Hay algo que quieras cambiar?"]; // Tu array de textos
const shuffleArray = (array) => [...array].sort(() => Math.random() - 0.5); // Mezcla aleatoria

  const [shuffledTexts] = useState(shuffleArray(texts)); // Mezcla una vez
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % shuffledTexts.length);
    }, 1450); // Cambia cada 1s

    return () => clearInterval(interval);
  }, [shuffledTexts]);

// const [color, setColor] = useState('#98c1d8'); // Color inicial de Elemento2
// const [color2, setColor2] = useState('#e0fbfb'); // Color inicial de Elemento2
// useEffect(() => {
//     const checkOverlap = () => {
//       const elemento1 = document
//         .querySelector(".section1a")
//         .getBoundingClientRect();
//       const sectionelemento2 = document
//         .querySelector(".sectionbuton")
//         .getBoundingClientRect();

//       // Comprobar si hay superposición entre Elemento1 y sectionelemento2
//       const isOverlapping =
//         elemento1.left < sectionelemento2.right &&
//         elemento1.right > sectionelemento2.left &&
//         elemento1.top < sectionelemento2.bottom &&
//         elemento1.bottom > sectionelemento2.top;

//       if (isOverlapping) {
//         setColor("#e0fbfb");
//         setColor2("#98c1d8"); // Cambia el color si hay superposición
//       } else {
//         setColor("#98c1d8");
//         setColor2("#e0fbfb"); // Vuelve al color original si no hay superposición
//       }
//     };
    


//     checkOverlap(); // Verificar en el montaje inicial
//     window.addEventListener("scroll", checkOverlap);
//     window.addEventListener("resize", checkOverlap);

//     // Limpiar los event listeners cuando el componente se desmonta
//     return () => {
//       window.removeEventListener("scroll", checkOverlap);
//       window.removeEventListener("resize", checkOverlap);
//     };
//   }, []);


const [showContact, setShowElement] = useState(false);

useEffect(() => {
  const checkScroll = () => {
    const scrollPercentage =
      (window.scrollY + window.innerHeight) / document.body.scrollHeight;

    if (scrollPercentage >= 0.5) {
      setShowElement(true);
    } else {
      setShowElement(false);
    }
  };

  checkScroll(); // Verificar en el montaje inicial
  window.addEventListener("scroll", checkScroll);
  window.addEventListener("resize", checkScroll);

  return () => {
    window.removeEventListener("scroll", checkScroll);
    window.removeEventListener("resize", checkScroll);
  };
}, []);

  useEffect(() => {
    setModalAgendar(modalagendar), [modalagendar];
  });

  useEffect(() => {
    if (Object.keys(respuestaT) != 0) {
      navigate("/psicologos/?match="+respuestaT.match+"&solicitud="+respuestaT.solicitud);
    }
  }, [respuestaT]);

  const params = useParams();
  const openModalProps = {
    loginModalOpen: params.type == "login" ? true : false,
  };

const backgroundStyle = !(isMobile)
  ? {
      width: "100vw",
      backgroundImage: `url(${isMobile ? backgroundmob : background})`, // Asegúrate de usar la imagen correcta
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }
  : {
       
  backgroundColor: "linear-gradient(90deg, rgba(164,249,255,1) 0%, rgba(148,238,252,1) 25%, rgba(132,227,242,1) 100%)"
    };

  return (
    <div style={backgroundStyle}>
      
      <NavBar {...openModalProps} />
      <div >

      <section className={`elemento ${isExpanded ? "expandedmobile" : ""}`}>
        <div className="centerin" >
          {/* <h1 className="fuente1">¡Todo empieza con un buen Match! </h1> */}
       
          <div className="subsection">


             <div className="row no-gutters" >
            <div className="col-lg-6"> 
                 <h1 className="fuente1">
            {/* Porque un buen match es lo más importante */}
            {/* Agenda con un profesional de salud mental en  */}
            Salud mental a un
          </h1>
              <div className="textleftland">    <div className="flex justify-center items-center h-screen bg-gray-100">

        <p className="fuente2"
        style={{marginBottom:"0", paddingBottom:"2px"}}>
                En vincular.se buscamos tu terapeuta ideal
              </p>
  
        <p className="fuente2"style={{marginTop:"0", paddingTop:"0"}} >
               Cuéntanos
              </p>
  
     
    </div></div>
                   {!(loading) && (
        <div className="loader-container" >
        
          <div style={{textAlign:"center"}}><BeatLoader color="#ee6c4d" size={15} margin={5} /></div>
          <h2 className="fuente2" style={{width:"100%",maxWidth:"100%",margin:"0 auto", textAlign:"center", color:"grey"}}>Estamos buscando terapeutas para ti</h2>
        </div>)}

        

              <article className="artichome">
                

   <h2
                  style={{ textAlign: "center", margin: "3% 0% 1% 0%" ,minWidth:"90%"}}
                  className="fuente2"
                >
                  {shuffledTexts[index]}
                </h2>
      
                
              
                <textarea
                  id="mensaje"
                  name="mensaje"
                  className={`caja-texto ${isExpanded ? "cajaexpanded" : ""}`}
                  // placeholder={"¿Qué estás sintiendo?"+"\n"+"¿Qué te preocupa?"+"\n"+"¿Cuáles son tus motivos de consulta?"}
                  value={txt}
                  style={{overflowY: "auto",}}
                  onChange={(e) => setTXT(e.target.value)}
                  onFocus={expandir}
                ></textarea>
                {showPopup && (
      <div style={{color:"red"}}>
 <AlertTriangle className="w-6 h-6 mr-3 text-red-600" />


  <span className="font-medium">Si no nos comentas, no te podemos ayudar</span>
</div>

      )}
                <div className="divboton">
                  <a href="#" data-toggle="modal" data-target={test_match==="test"?"":"#agendarmodal"} 
                  onClick={()=>{handleMsgUser()
                }}>
                    <button
                      className="boton-ovalado"
                      
                      
                    >
                      <div style={{fontWeight:"600"}}>Encuentra</div>
                      <div>
                        
                      </div>
                    </button>
                  </a>
               
                </div>
                <p
                  className="message"
                  style={{ marginTop: "25px", textAlign: "center" }}
                >
 
 
              <a
                    href="/ayuda"
                    className="boton-enlace"
                    style={{ textShadow: "2px 2px 4px #a0a0a0" }}
                  >
                    <b> No sé cómo buscar, ayúdame a encontrar</b>{" "}
                    <i className="fa-solid fa-circle-question"></i>
                  </a>

                  
                </p>
                    
              </article> </div>
            <div className="col-lg-6">
                
              <div className="imglandcontainer">
              <img src={Image} className="imglnd" alt="red de psicologos" />
            
                 <div className="textcontainerimage"> <ExplainText/> </div>
            </div>
        </div>
          </div>
          </div>
        </div>
      </section>
       
      <div style={{marginLeft:"0px",marginTop:""}}>
      <div className="" style={{width:"88%", margin:"0 auto"}}>
        <SlidingBanner />
      </div>
      </div>
      <div className="section1a" id="section1a" style={{width:"88%", margin:"0 auto", marginTop:"30px"}}>
        <HomeSectionA />
      </div>

  
      </div>
          <div >
     {showContact&&  <Contact  />}
      </div>  
      <div>
     <Fotr /></div>
         <div >
        <Modal_ isOpen={modalagendar} id="agendarmodal">
       {FormHora(handleModalAgendar,txt,setLoading)}
      </Modal_>
      </div>
    </div>
  );
};
export default Home;
