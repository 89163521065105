
import React, { useState , useRef, useEffect, useContext}  from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import "./Foro.css"
import Cookies from "js-cookie";
import ForoUserCard from "./ForoUserCard";
import ResponsePost from "./ResponseForm";
import PhotoGallery from "./FotoCards/CardPhoto";
 import { FadeLoader } from 'react-spinners';

import { UserContext } from "../login/login-context";



const PostFormat = () =>{


const navigate=useNavigate()
const { URL_post } = useParams();
const  [principalpost,setPrincipalPost]= useState({})

useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`/api/foropostfeeder/${URL_post}`);
        setPrincipalPost(response.data[0])
        setResponses(response.data[1])
        console.log("Respuesta forofeeder:", response.data);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        } 
        finally {
        // Finaliza la carga
        setLoading(false)
      }
    };
    fetchData();
  }, []);




const [responder,setResponder]=useState(false)
// eslint-disable-next-line no-unused-vars
const [user,setUser]=useContext(UserContext)

 const add_new_response = (newItem) => {
  const newResp= {msg:newItem.msg,images:newItem.imgs,likes:[]};
  upload_response(newResp)
  
  newResp.profile=user.profile_link
  newResp.name=user.name;
  
  newResp.lastname=user.lastname;
  
  newResp.edited="no"
  setResponses(prevItems => [...prevItems, newResp]);
  setResp(false)
   // Agrega el nuevo elemento sin mutar el estado
};


const   setResp=(value)=>{
    setResponder(value);
   
}
const setEditResp=(index)=>{
  console.log(index)
    setAction_resp("edit");
    (indexEdit===null&& index>=0)?setIndexEdit(index):setIndexEdit(null);
    


   
}

const[loading,setLoading]=useState(true)
const deleteResponse=(i)=>{
const response_id=responses[i].id
const newresponses = responses.filter((_, index) => index !== i);
setResponses(newresponses)
delete_response(response_id)
}
 const delete_response = (response_id) => {
    axios 
      .post(`/api/delete_response/`, {"response_id":response_id}, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        
        window.location.reload();

      })
      .catch((error) => console.log(error));
  };

const delete_post = (post_id,categoria) => {
    axios
      .post(`/api/delete_post/`, {"response_id":post_id}, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        
        navigate(`/home/foro/section?category=${categoria}`)

      })
      .catch((error) => console.log(error));
  };

  const edit_response_to = (data) => {
    axios
      .post(`/api/edit_response/`, data, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        window.location.reload();


      })
      .catch((error) => console.log(error));
  };

    const edit_post_to_api = (data) => {
    axios
      .post(`/api/edit_post/`, data, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        window.location.reload();


      })
      .catch((error) => console.log(error));
  };

const section1Ref = useRef(null);
const scrollToSection = (ref) => {
    if (ref ) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

const ismine=(profilelink)=>{
  if (user && user.profile_link) {
    if(profilelink===user.profile_link) {return true}
    else{return false}

    }
}
// const Like=()=>{
  
// }

const[indexEdit,setIndexEdit]=useState(null)
  const [action_resp,setAction_resp]=useState("resp")

 const upload_response = (newresponse) => {
    axios
      .post(`/api/newresponse/${URL_post}`, newresponse, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        


      })
      .catch((error) => console.log(error));
  };

  const formatearTexto = (texto) => {
  // 1. Convertir enlaces en HTML
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const domainRegex = /\b([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\b/g;

  // Reemplazar los enlaces
  texto = texto.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });

  // Reemplazar dominios como enlaces completos
  texto = texto.replace(domainRegex, (domain) => {
    return `<a href="https://${domain}" target="_blank">${domain}</a>`;
  });



  // 4. Convertir _cursiva_ en <i>cursiva</i>
  texto = texto.replace(/_(.*?)_/g, "<i>$1</i>");

  // 5. Convertir *negrita* en <b>negrita</b>
  texto = texto.replace(/\*(.*?)\*/g, "<b>$1</b>");

  return texto;
};



const Ilike=(typepost,index)=>{
  if (typepost==="principal"){
  const ilike = principalpost["likes"]?principalpost["likes"].some(obj => obj.linkprofile === user.profile_link):false;return ilike}
  else{
      const ilike = responses[index]["likes"]?responses[index]["likes"].some(obj => obj.linkprofile === user.profile_link) :false;return ilike}

  }

 const handle_like_api = (data) => {
    axios 
      .post(`/api/handle_like/`, data, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        
        

      })
      .catch((error) => console.log(error));
  };

 

const handleLike = (typepost, index, Ilike) => {
  const data = {
    post_category: principalpost["category"],
    post_id: principalpost["id"],
    linkprofile: user.profile_link,
  };

  if (!Ilike(typepost, index)) {
    // Si no le gusta, agregar a la lista
    if (typepost === "principal") {
      console.log("espost");
      setPrincipalPost(prevPrincipal => ({
        ...prevPrincipal,
        likes: [...prevPrincipal.likes, data], // Agregar el like
      }));
      data.action = "addlike";
      data.type = "post";
      handle_like_api(data);
    } else {
      console.log("esresponse");
      data.response_id = responses[index]["id"];
      setResponses(prevResponses =>
        prevResponses.map(response =>
          response.id === responses[index]["id"]
            ? { ...response, likes: [...response.likes, data] }
            : response
        )
      );
      data.type = "response";
      data.action = "addlike";
      handle_like_api(data);
    }
  } else {
    // Si ya le gustaba, eliminar de la lista
    if (typepost === "principal") {
      console.log("eliminando like de principal");

      // Buscar el like antes de eliminarlo
      const like = principalpost.likes.find(like => like.linkprofile === user.profile_link);

      setPrincipalPost(prevPrincipal => ({
        ...prevPrincipal,
        likes: prevPrincipal.likes.filter(like => like.linkprofile !== user.profile_link), // Eliminar el like
      }));

      if (like) {
        console.log("Like eliminado:", like);
        const data_to_serv = { action: "dislike", id: like.id };
        handle_like_api(data_to_serv);
      }
    } else {
      console.log("eliminando like de response");

      // Buscar el like antes de eliminarlo
      const response = responses.find(resp => resp.id === responses[index]["id"]);
      const like = response ? response.likes.find(like => like.linkprofile === user.profile_link) : null;

      setResponses(prevResponses =>
        prevResponses.map(response =>
          response.id === responses[index]["id"]
            ? { ...response, likes: response.likes.filter(like => like.linkprofile !== user.profile_link) }
            : response
        )
      );

      if (like) {
        console.log("Like eliminado:", like);
        const data_to_serv = { action: "dislike", id: like.id };
        handle_like_api(data_to_serv);
      }
    }
  }
};


 const [responses,setResponses]=useState([{}])
 
const edit_response =(newresponse,i)=>
{
newresponse.likes=[]
edit_response_to({"id":responses[i].id,"msg":newresponse.msg,"images":newresponse.imgs});

  const new_response_edit=responses.map((response, index) => index === i ? newresponse : response)

setResponses(new_response_edit);
setAction_resp("resp");
setResponder(false);
console.log(responses[i])
setIndexEdit(null)
}

const edit_post =(newpost)=>
{
  console.log(newpost)
edit_post_to_api({"id":newpost.id,"msg":newpost.msg,"images":newpost.imgs});
setIndexEdit(null)
}




return(


        <>
        
        <div style={{maxWidth:"1350px", margin:"0 auto"}}>
          {loading?<div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh" // Ajusta según necesites
    }}>
      <FadeLoader color="#36d7b7" />
    </div>:
        <><div className="titlepost">{principalpost ? principalpost["title"] : "no hay titulo"}</div><div className="postbox">

              <div className="postsubbox">
                <div className="postsubboxleft">

                  <ForoUserCard name={principalpost.name} lastname={principalpost.lastname} link={"/ps/" + principalpost.linkprofile} img={principalpost.user_image} enfoque={principalpost["enfoque"] ? principalpost["enfoque"] : ""} />
                </div>
                <div className="postsubboxright">
                  <p style={{whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatearTexto(principalpost["msg"])) }}/>
                  {/* <div className="msgpost"style={{whiteSpace: "pre-line" }}>{principalpost["msg"]}</div> */}
                  {(indexEdit === null) && action_resp === "edit" && <ResponsePost setResponder={setAction_resp} add_new_response={edit_post} action={action_resp} response={principalpost} index={0} />}

                  <>{!((indexEdit === null) && action_resp === "edit") && principalpost.images && principalpost.images.length > 0 && <div><PhotoGallery images={principalpost.images.split("<>")} /> </div>}</>

                  {principalpost.edited === "si" && (<div style={{ display: "flex", marginTop: "5px", fontStyle: "italic", minWidth: "100%", justifyContent: "left" }}> Editado</div>)}

                </div>
              </div>
              <div></div>
              <div style={{ display: "flex", minWidth: "100%", justifyContent: "right" }}>

                <a className="butonsforopost" 
                onClick={
                        (e)=>{e.preventDefault(),
                        handleLike("principal", 0, Ilike)

                        }}
                         style={{ textDecoration: "underline" }}><p><i style={{ color: "#3d5a80", }} className={`fa-${Ilike("principal",0)?"solid":"regular"} fa-heart`}></i><span>Me gusta:{principalpost.likes.length}</span></p></a>
                {/* <p className="butonsforopost">{principalpost.likes}</p> */}
                <a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={() => { setResp(true), scrollToSection(section1Ref); } }><p>Responder: {principalpost["resp"]}</p></a>
                {ismine(principalpost.linkprofile) && (
                  <><a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={() => { delete_post(principalpost.id, principalpost.category); } }><p><i style={{ color: "#3d5a80" }} className="fa-solid fa-trash"></i><span>Eliminar</span></p></a>
                    <a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={(e) => { e.preventDefault(), setEditResp(); } }><p><i style={{ color: "#3d5a80" }} className="fa-solid fa-pen"></i><span>Editar</span></p></a>
                  </>)}

              </div>
            </div><div style={{ maxWidth: "1300px", width: "90%", textAlign: "left", marginLeft: "10%" }}>
                {responses.length > 0 && responses.map((resp, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <div className="postbox ">
                    <div className="postsubbox">
                      <div className="postsubboxleft">
                        <ForoUserCard name={resp.name} lastname={resp.lastname} link={"/ps/"+resp.linkprofile} img={resp.user_image} enfoque={resp.enfoque} />
                      </div>
                      <div className="postsubboxright">

                        {!(index === indexEdit && action_resp === "edit") && (                  <p style={{whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatearTexto(resp["msg"])) }}/>
)}
                        {index === indexEdit && action_resp === "edit" && <ResponsePost setResponder={setAction_resp} add_new_response={edit_response} action={action_resp} response={resp} index={index} />}
                        <>{!(index === indexEdit && action_resp === "edit") && resp.images && resp.images.length > 0 && <div><PhotoGallery images={resp.images.split("<>")} /> </div>}</>
                        {resp.edited === "si" && (<div style={{ display: "flex", marginTop: "5px", fontStyle: "italic", minWidth: "100%", justifyContent: "left" }}> Editado</div>)}

                      </div>
                    </div>

                    <div style={{ display: "flex", minWidth: "100%", justifyContent: "right" }}>
                      <a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={
                        (e)=>{e.preventDefault(),
                          handleLike("response",index,Ilike)
                        }}
                        ><p><i style={{ color: "#3d5a80" }} className={`fa-${Ilike("response",index)?"solid":"regular"} fa-heart`}></i><span>Me gusta:{resp.likes.length} </span></p></a>
                      {/* <p className="butonsforopost">{resp.likes}</p> */}

                      <a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={() => { setResp(true), scrollToSection(section1Ref); } }><p>Responder</p></a>
                      {ismine(resp.linkprofile) && (
                        <><a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={() => { deleteResponse(index); } }><p><i style={{ color: "#3d5a80" }} className="fa-solid fa-trash"></i><span>Eliminar</span></p></a>
                          <a className="butonsforopost" style={{ textDecoration: "underline" }} onClick={(e) => { e.preventDefault(), setEditResp(index); } }><p><i style={{ color: "#3d5a80" }} className="fa-solid fa-pen"></i><span>Editar</span></p></a>
                        </>)}

                    </div></div>
                ))}
                <article>
                  <div style={{ display: responder ? "" : "none" }}>
                    {action_resp === "resp" && responder && <ResponsePost setResponder={setResp} add_new_response={add_new_response} action={action_resp} response={{}} index={0} />}
                  </div>
                </article>


              </div><div style={{ color: "white" }} ref={section1Ref}>referencia</div></>
        }
                </div>
                
           </>
        
    )
}

export default PostFormat

