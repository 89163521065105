
import React, { useState ,useEffect }  from "react";
import "./Foro.css"
import axios from "axios";
import { ForoCard } from "./ForoSectionCard";
import { Outlet, Link , useLocation} from "react-router-dom";




const Foro = () =>{

    const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const category = queryParameters.get("category");

const Categorias = [ {name:"Casos Clínicos",desc:"Dudas sobre pacientes, supervisiones des casos actuales o casos antiguos y que busques ayuda en la comunidad",link:"section?category=clinica",clave:"clinica"},
  {name:"Soporte Laboral",desc:"Cualquier tema de discusión que sea complementario al trabajo como: conseguir nuevos pacientes, económicos, consejos, etc.",link:"section?category=laboral",clave:"laboral"},
  {name:"Académico",desc:"Contenido académico, investigación o continuación de estudios",link:"section?category=academic",clave:"academic"},
 
{name:"General",desc:"Contenido general relacionado a temas psicológicos",link:"section?category=general",clave:"general"},
 
    {name:"vincularse",desc:"Dudas o comentarios respecto la plataforma o la comunidad en general",link:"section?category=vincularse",clave:"vincularse"},]
// const [selectedTopic,setSelectedTopic]=useState("")



//   const toggleLoading = () => {
//     setLoading((prevLoading) => !prevLoading);
//   };
// // eslint-disable-next-line no-unused-vars
// const [posts,setPosts]=useState([{title:"Hola vengo a flotar",msg:"Hola cómo estás",photo:"nophoto",category:"General"},{title:"Hola vengo a flotar2",msg:"Hola cómo bien y tú",photo:"nophoto",category:"Casos Clínicos"}])
// eslint-disable-next-line no-unused-vars, no-unused-vars
const [categoryPost,setCategoryPost]=useState("")
// const getPostSection=(categoria)=>{
//         setCategoryPost(categoria);
          const [tosect,setTosection]=useState(false)
  const minizsect =()=>{
    setTosection(true)
  }
const [qbycat,setQbyCat]=useState({})
  useEffect(() => {
    setTosection(false)
    
    
  }, [location]); 
 
  const getQcategoria = async () => {
    try {
      const response = await axios.get("/api/category_post");
      setQbyCat(response.data);
      console.log(response.data)
    } catch (error) {
      console.log("ERROR");
    }
  };

    useEffect(() => {
   getQcategoria()
    
    
  }, []); 

 

    return(
        
        <>
          <div className="perfilcontenedorwk">

                 
                 <div style={{margin:"0 auto"}}>
                  <div style={{display:"flex", flexWrap: "wrap"}}>
                 {Categorias && Categorias.map((categoria)=>(
                        // eslint-disable-next-line react/jsx-key
                         <Link className={`linkrouter ${tosect===true||category?"linkminimized":""}`} to={categoria.link}  onClick={()=>minizsect()}>{
                      ForoCard(categoria.name,categoria.desc,tosect,category,qbycat[categoria["clave"]])}</Link>))}</div>
                
                </div>
          
          </div>
          
    
          <Outlet/>
         
        </>
    )
}
export default Foro

