/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";

import Welcome from "../img/Homewelcome.png";
import selfcare from "../img/selfcare.png";
import axios from "axios";
import "../login/login.css";
import Cookies from "js-cookie";
import Resizer from "react-image-file-resizer";
import { Post } from "./post";

// import { UserContext } from "../login/login-context";

export function HomePost() {
 
 
  

 
  return (
    <section className="wksp_section" >
    <article>
       <p className="headerhome"> ¡Hola ! Esto es lo que tenemos de nuevo para ti hoy.</p>
    </article>
    <article className="boxwk">
  <div className="Postwksp">
  <p className="posttext">Mantente conectado pronto tendremos nuevas noticias.
  </p>  
  <article className="imgcontainer">
  <img
    className="imghead"
    href="/"
    src={Welcome}
    alt="Logo Vinculandose"
  />
  </article>


  </div>
    </article>
   
   <Post/>


    </section>
  );
}
