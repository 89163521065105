import "./Foro.css"
import React ,{ useEffect,useState } from "react";
  


export const ForoCard = (SectionName,descripcion,tosect, category,qcat) => {
  const title={general:"General",academic:"Académico",clinica:"Casos Clínicos",laboral:"Soporte Laboral",vincularse:"vincularse"}

const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
    useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
  return (
    <>
     
      <div className={`  forocard fc-${tosect|| category ?SectionName:""} ${tosect|| category?"forotosect":""}`} style={{padding:"0" ,border:title[category]===SectionName?"2px solid black":"",  boxShadow:title[category]===SectionName?"1px 5px 10px #ff70239d":""}}>
       
          
          
          <div>
            <div className={`forotitle fc-${SectionName}`}>
             
          <h2 style={{color:"white", margin:"0"}}>{SectionName}</h2></div>
           <div style={{padding:"2%",display:tosect|| category?"none":""}} ><p style={{color:"black"}}>{descripcion.length>100 && isMobile?descripcion.substr(0,90)+"...":descripcion}</p>
           <div className="bottomforocard" ><p style={{color:"black"}}>Cantidad de Post:{qcat?" "+qcat:" 0"}</p></div>
          </div>
          </div>
   
          {/* {listItems ? <div className="catalog__profile_esp">{listItems}</div> : <></>} */}

  

      </div></>
    
  );
};
