/* eslint-disable no-unused-vars */

import React, { useState , useEffect}  from "react";
import Resizer from "react-image-file-resizer";
import PropTypes from 'prop-types';
import axios from "axios";
import PhotoGallery from "./FotoCards/CardPhoto";
import { Axios } from "axios";



// eslint-disable-next-line react/prop-types
const ResponsePost = ({setResponder, add_new_response, action,response,index}) =>{

    const [imgs,setImgs]=useState(action==="edit" && response.images?response.images.split("<>"):[])

 const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    files.forEach((file) => {
      Resizer.imageFileResizer(
        file,
        1080, // max width
        1080, // max height
        "JPEG", // format
        70, // quality
        0, // rotation
        (uri) => {
          setImgs((prevImgs) => {
            const updatedImgs = [...prevImgs, uri].slice(-3); // Mantener solo las últimas 3 imágenes
            return updatedImgs;
          });
        },
        "base64"
      );
    });
  };
 const new_response = response || ""; 
 
const[newMesg,setNewMesg]=useState(action==="edit"?response.msg:"")

const removePhoto=(i)=>{
  const newimgs = imgs.filter((_, index) => index !== i);
  setImgs(newimgs)
}


  
  const UploadResponse=()=>{
  const images_str =imgs.join("<>");
  new_response.imgs=images_str;
  new_response.msg=newMesg
  console.log(new_response)
if (newMesg.length>0){
  action==="edit"?add_new_response(new_response,index):
  add_new_response(new_response)}

  else{return}

  }

    return(

        <>
        
           <div className="postformnewpost" style={{maxWidth:"1350px", margin:"0 auto", minHeight:"200px", justifyContent:"center", alignContent:"center"}}>
                  <p>Escribe acá tu respuesta</p>  
                    <textarea
                    defaultValue={action==="edit"?response.msg:""}
                     onChange={(e) => setNewMesg(e.target.value)}
                      maxLength={1200}
                      style={{overflow:"auto"}}
          className={`caja-texto-profile`}
        ></textarea>
<p>Puedes subir 3 imagenes acá:</p>
               <label style={{color:"black"}} className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-lg inline-block">
        Subir Imagen
        <input
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          multiple
          className="hidden"
        />
      </label>
               <div className="flex gap-2 mt-4">
        {imgs.map((img, index) => (<> 
          <img style={{maxWidth:"15%"}}
            key={index}
             src={img}
            alt={`Uploaded ${index + 1}`}
            className="w-24 h-24 object-cover rounded-lg"
          />
                <button
              className="remove-btn"
              onClick={() => removePhoto(index)}
              aria-label="Remove photo"
            >
              &#10005;
            </button></>
          
        ))}
         
      </div>
      <div style={{justifyContent:"right", alignContent:"right",textAlign:"right"}}>
               <button className="buttonforo" onClick={()=>(UploadResponse())}>Responder</button>
               <button className="buttonforo" onClick={()=>(action==="edit"?setResponder("resp"):setResponder(false))}>Cancelar</button>
         
             </div>
                </div>

           </>
        
    )
}
// const ResponsePost = ({setResponder, add_new_response, action,response,index}) =>{
// import PropTypes from 'prop-types';
ResponsePost.propTypes = {
  setResponder: PropTypes.func,
  add_new_response: PropTypes.func,
  action:PropTypes.string,
  response:PropTypes.object,  // name debe ser una cadena de texto y es obligatorio
     // age debe ser un número y es obligatorio
};

export default ResponsePost

