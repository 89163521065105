import React, { useState, useRef, useEffect } from "react";
import "./NavBar.css"; // Asegúrate de tener tu archivo de estilos CSS
import "../../App.css";
import PropTypes from "prop-types";
import Logo from "../img/LogoLanding.svg";
import Modal_ from "../Modals/Modal_";
import Login from "../login/Login";
import RegistrationForm from "../registration/Form";

const NavBar = ({loginModalOpen=false}) => {
  NavBar.propTypes = {
    loginModalOpen: PropTypes.any,
  };
  const loginRef = useRef();
  const registrationRef = useRef();
  const [isMobileMenuOpen1, setIsMobileMenuOpen1] = useState(false);

  const toggleMobileMenu1 = () => {
    setIsMobileMenuOpen1(!isMobileMenuOpen1);
  };

  const switchToRegistration = () => {
    loginRef.current.click();
    registrationRef.current.click();
  };

  useEffect(() => {
    if (loginRef.current && loginModalOpen) {
      loginModalOpen = false;
      loginRef.current.click();
    }
  }, [loginRef])

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <a href="/">
          <img
            className="imghead"
            href="/"
            src={Logo}
            alt="Logo Vinculandose"
          />
        </a>
      </div>

      {/* Mostrar el botón de menú en dispositivos móviles Agregar un dropdown*/}
      <div className="mobile-menu-button" onClick={toggleMobileMenu1}>
        <i
        
          className=".fanav fa-solid fa-bars"
          style={{
            fontSize: "30px",
            color: "#154480",
            margin: "0px",
            padding: "0px",
          }}
        ></i>
      </div>

      {/* Mostrar la lista de navegación en pantallas grandes */}
      <ul className={`nav-list ${isMobileMenuOpen1 ? "mobile-menu-open" : ""}`}>
        <li className="item-lista">
          Soy Profesional
          <ul className="submenu">
            <li className="submenu-item">
              <a
                onClick={() => {
                  isMobileMenuOpen1 && toggleMobileMenu1();
                }}
                href="#"
                data-toggle="modal"
                data-target="#registrationModal"
                ref={registrationRef}
              >
                Registro
              </a>
            </li>
            <li className="submenu-item">
              <a
                onClick={() => {
                  isMobileMenuOpen1 && toggleMobileMenu1();
                }}
                href="#"
                data-toggle="modal"
                data-target="#exampleModal"
                ref={loginRef}
              >
                Iniciar sesión
              </a>
            </li>
          </ul>
        </li>
        {/* <li className="deshorizontal">
          <a
            href="/nosotros"
            className="boton-enlace"
            style={{ textShadow: "2px 2px 4px #a0a0a0" }}
          >
            Nosotros
          </a>
        </li> */}
        <li className="deshorizontal">
          <a
            href="/faq"
            className="boton-enlace"
            style={{ textShadow: "2px 2px 4px #a0a0a0" }}
          >
            Preguntas Frecuentes
          </a>
        </li>

        {/* <li className="deshorizontal">
          <a
            href="/contacto"
            className="boton-enlace"
            style={{ textShadow: "2px 2px 4px #a0a0a0" }}
          >
            Contacto
          </a>
        </li> */}
      </ul>

      <Modal_
        isOpen={true}
        id="exampleModal"
      >
        <Login switchModal={switchToRegistration} />
      </Modal_>
      <Modal_
        isOpen={true}
        id="registrationModal"
      >
        <RegistrationForm />
      </Modal_>
    </nav>
  );
};
export default NavBar;