/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Children ,useState} from "react";
import PropTypes from "prop-types";
import "./Modals.css";


const ModalButton2 = ({ toShow, onClose, children }) => {
  if (!toShow) return null;
  // const [limit, setLimit] = useState(window.innerWidth < 500?"20%":"8%");
  return (
    <div
      className="modal fade show"
      style={{ display: 'block' }}
      aria-labelledby="Modal"
      aria-hidden="true"
    >
      <div className="modal-body">
            <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh" // Ajusta según necesites
    }}>
      <div style={{width:"100%"}} className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={onClose}  // Este es el botón para cerrar el modal
              aria-label="Close"
            ></button>
          </div>
          {children}</div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ModalButton2;