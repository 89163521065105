import React, {useState, useEffect} from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import NewPostForm from "./NewPost";
import { FadeLoader } from 'react-spinners';
import DOMPurify from "dompurify";
// eslint-disable-next-line react/prop-types
const SectionForo = ({category}) =>{
    // eslint-disable-next-line no-unused-vars
    const queryParameters = new URLSearchParams(window.location.search);
    const page = queryParameters.get("page");
    const categoria=queryParameters.get("category");

    // eslint-disable-next-line no-unused-vars
    const [limit, setLimit] = useState(window.innerWidth < 500?50:100);
    const [posts,setPosts]=useState([{title:"Aun no hay post",msg:"Aun no hay post",user:"Luis Alvarez",date:"10/01",responses:"4",subcategory:"X",linktopost:"/home/post/ASDnkiv"},
    ])
    const [newpost,setNewpost]=useState(false)
    const writePost=()=>{
        setNewpost(true)
    }
    const formatearTexto = (texto) => {



  // 4. Convertir _cursiva_ en <i>cursiva</i>
  texto = texto.replace(/_(.*?)_/g, "<i>$1</i>");

  // 5. Convertir *negrita* en <b>negrita</b>
  texto = texto.replace(/\*(.*?)\*/g, "<b>$1</b>");

  return texto;
};


    useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("estoy preguntando por esta categoria"+categoria)
        const response = await axios.get(`/api/forosectionfeeder/${categoria}`);
        console.log(response.data)
        setPosts(response.data)
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        
      } 
      finally{
        setLoading(false)
      }
    };
    fetchData();
  }, [categoria]); 
const[loading,setLoading]=useState(true)

    return(
        <div className="cuerpo" style={{width:"90%", margin:"0 auto"}}>
  <div style={{width:"100%", backgroundColor:"#3d5a80",color:"white",marginTop:"10px"}}>Foro {category}</div>
  {/* <div style={{margin:"0 auto"}}><label>Buscar:</label><input  style={{width:"80%", margin:"0 auto"}} type="text" /></div> */}
  {loading?<div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh" // Ajusta según necesites
    }}>
      <FadeLoader color="#36d7b7" />
    </div>: <> <div style={{textAlign:"right"}}> 
            {!(newpost)&&<button className="buttonforo" onClick={()=>{writePost()}}>Nuevo Post</button>}
        </div>
        {newpost&&<div><NewPostForm categoria={categoria} setNewPost={setNewpost} category_title={category}/></div>}
        <div>
            {posts.map((post)=>
                // eslint-disable-next-line react/jsx-key
                (<Link to={"/home/post/"+post["linktopost"]}>
                <div style={{margin:"0.5%", border:"1px solid grey", borderRadius:"10px"}}>
                <div style={{color:"white", backgroundColor:"#98c1d8", display:"flex", justifyContent:"space-between", borderRadius:"10px 10px 0px 0px"}}><p style={{color:"white"}}>{post["title"]}</p><p style={{color:"white"}}>Última actividad: {post["date"]}</p></div>
                <div style={{margin:"0.5%"}}>                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.msg.length>limit?formatearTexto(post.msg.substr(0,limit))+"...":formatearTexto(post.msg)) }}/>
</div>
                <div style={{display:"flex", justifyContent:"space-between"}}><p>Autor:{post["user"]}</p><p> Respuestas: {post["responses"]}</p></div></div></Link>
            ))}

        </div>
        <div style={{display:"flex",justifyContent:"center", width:"100%"}}> <a style={{display:(!(page)||page===1)?"none":""}}> ◄ </a> <span style={{marginLeft:"5px", marginRight:"5px"}}>Ver más</span> <a> ►</a></div>
          </>}
        </div>
        
    );
    




};
SectionForo.propTypes = {
  category: PropTypes.string,
 // name debe ser una cadena de texto y es obligatorio
     // age debe ser un número y es obligatorio
};
export default SectionForo


