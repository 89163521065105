/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";

// import "../workspace/Work.css";
import Psicos from "../img/psicos.png"
import axios from "axios";
import "./sectionshome.css";


// import "../workspace/Work.css";

export function HomeSectionA() {
  const [registers, setRegister] = useState();
  // const [diagnostics, setDiagnostics] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/homefeeder`);
        setRegister(response.data["regsister"]);
        // setDiagnostics(response.data["diagnostics"]);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <section className="homecontedor">
    <div className="row secrtionrectanglehome " >  

      <div className="col-lg-8" style={{justifyContent:"center", justifyItems:"center",textAlign:"center"}}  >
       <img src={Psicos} className="" alt="psicólogos" style={{margin:"0 auto", width:"80%",zIndex:"99999  "}} />
      </div>
      <div className="col-lg-4">
        <div style={{display:"block", alignItems:"center", alignContent:"center", textAlign:"center", padding:"0 auto", height:"100%"}}>
            <article className="numbershome">
          
          <p className="estadistcnumberhome">{registers} </p>
          <p className="descriptionnumbershome">Profesionales registrados</p>
        </article>
        {/* <article className="numbershome">
          
          <p className="estadistcnumberhome">{diagnostics} </p>
          <p className="descriptionnumbershome">
           Diagnósticos atendemos
          </p>
        </article> */}

       
        </div>
        
       
      </div>
      
    </div>
    
    </section>
  );
}
