
import React, { useState ,useEffect } from "react";
import "./Foro.css"
import axios from "axios";
import { useLocation } from "react-router-dom";
import SectionForo from "./ForoSection";





const ForoRouter = () =>{

  const queryParameters = new URLSearchParams(window.location.search);
  const category = queryParameters.get("category");
  const location = useLocation()
  
  const [post,setPost]=useState("")


    useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/forofeeder/${category}`);

        setPost(response.data);

        console.log("Respuesta servicesfeeder:", response.data);
      } catch (error) {
        console.error("Error al cargar servicesfeeder:", error);
      }
    };
    
    fetchData();
    setPost(category)
  }, [location]);
  const title={general:"General",academic:"Académico",clinica:"Casos Clínicos",laboral:"Soporte laboral",vincularse:"vincularse"}

    return(
        <>
        
        <SectionForo category={title[post]}/>
      
      
          </>

    )
}
export default ForoRouter

