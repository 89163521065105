import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";

import Home from "./components/Home";
import About from "./components/aboutus/About";
import Faq from "./components/FAQ/FAQ";
import Login from "./components/login/Login";
import Validation from "./components/registration/Validation";
import CompleteRegistration from "./components/registration/Complete";
import Pproftest from "./components/Profile/Pprofile";
import AgendaPublica from "./components/agendamiento/agendapublica";
import Catalog from "./components/catalog/Catalog";
import Help from "./components/FAQ/Help";
import Workspacehome from "./components/workspace/workspace"
import Dashboard from "./components/dashboard/dashboard";

import RecoverPassword, {
  SetPassword,
} from "./components/registration/Recovery";
import { UserProvider } from "./components/login/login-context";

import Notfound from "./components/Notfound/Notfound";
import RequestCompleted from "./components/agendamiento/requestCompleted";

import WorkspaceCalendar from "./components/agenda-calendario/WorkspaceCalendar";
import Foro from "./components/Foro/ForoEsqueleto";
import ForoRouter from "./components/Foro/ForoRouter";
import TutorialMap from "./components/workspace/maptutorial";
import { HomePost } from "./components/workspace/home_post";
import { Formaccount } from "./components/workspace/config_profile";
import PostFormat from "./components/Foro/PostFormat";


function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>

          <Route path="/" element={<Home />} />

          <Route path="/verify" element={<Validation />} />
          <Route
            path="/recover_password/set_password"
            element={<SetPassword />}
          />
          <Route
            path="/registration_complete"
            element={<CompleteRegistration />}
          />
          <Route path="/confirmacion_solicitud" element={<RequestCompleted />} />
          <Route path="/action/:type" element={<Home />} />
          <Route path="/nosotros" element={<About />} />
          <Route path="/home" element={<Workspacehome />}>
            <Route path="" element={<HomePost />} /> 
            <Route path="settings" element={<Formaccount />} /> 
            <Route path="agenda" element={<WorkspaceCalendar />} /> 
            <Route path="foro" element={<Foro />} key="foro" > 
              <Route path="section" element={<ForoRouter/>} key="section"/>
            
            
            </Route>
            <Route path="ayuda" element={<TutorialMap />} /> 
            <Route path={"post/:URL_post"} element={<PostFormat/>} key="postforo"/>
            
          </Route>
          <Route path="/faq" element={<Faq />} />
          <Route path="/login" element={<Login />} />
          <Route path="/recover_password" element={<RecoverPassword />} />
          <Route path={"/ps/:user_id"} element={<Pproftest />} />
        
          <Route path={"/agenda/:user_id"} element={<AgendaPublica />} />
          <Route path="/psicologos" element={<Catalog />} />
          <Route path="/ayuda" element={<Help />} />
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/404" element={<Notfound/>} />
          <Route
      path="*"
      element={<Navigate to="/404" replace={true} />}
    />

        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
