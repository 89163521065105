/* eslint-disable react/prop-types */

import React from "react";
import { motion } from 'framer-motion';

// import "../workspace/Work.css";

import "./sectionshome.css";
// import "../workspace/Work.css";

export function ExplainText() {

    

 
  return (
    <div className="bulletsover" style={{width:"100%", margin:"0 auto"}}> 
      <motion.div
        initial={{ x: '-10%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{duration: 0.5, ease: 'easeInOut', delay:1 }}
        className="text-2xl font-bold"
      >
        <div className="rombotexover"><p className="rombotexover">Cómo te sientes</p></div>
      </motion.div>
      
          <motion.div
        initial={{ x: '-10%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{duration: 0.5, ease: 'easeInOut', delay:1.5 }}
        className="text-2xl font-bold"
      >
        <div className="rombotexover"><p className="rombotexover">Qué prefieres</p></div>
      </motion.div>
                <motion.div
        initial={{ x: '-10%', opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{duration: 0.5, ease: 'easeInOut', delay:2 }}
        className="text-2xl font-bold"
      >
        <div className="rombotexover"><p className="rombotexover">Algo sobre ti</p></div>
      </motion.div>

 </div>
  );
}
