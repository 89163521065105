import Puntos from "../img/puntos.png";
import Nophoto  from "../img/nonprof.webp";
export const Card = (photo, name, title, university,exp,minprice,modalidad, href,solicitud) => {
  const formato = new Intl.NumberFormat('es-ES', { style: 'decimal', decimal: ',', thousand: '.' });  
  // var listItems;
  // if (items != undefined) {
  //   listItems = items.map((item) => (
  //     <div
  //       key={item}
  //       className=" px-1 py-1   catalog__espprof"
  //     >
  //       {item.substr(0,35)}
  //     </div>
  //   ));
  // }

  return (
    <div className="col mt-4 psico-catalog">
      
      <div className="card catalog__card2" style={{ width: "100%" }}>
        <div className="borderlinecard">
        <div className="imgframe2 imgcatalog">
          <img className="dots-us-catalog" src={Puntos} />
          <img
            className="img-circular-us-catalog"
            src={photo? photo:Nophoto}
            alt="Descripción de la imagen"
          />
        </div>
        <div className="card-body catalog-text">
          <div>
            <h5 className="lfh catalog__lfh">{name}</h5>
          </div>
          {/* <p>Atención: {title}</p> */}
          
          
          <p className="pcardcataloginfo">{university==="Sin información"?"":((university.length>30?university.substr(0,31):university))}</p>
          <p className="pcardcataloginfo"><span>{exp} </span>{exp?(exp===1?"año de experiencia":"años de experiencia"):"Sin información de su experiencia"}</p>
          <p className="pcardcataloginfo">{modalidad==="Sin información"?"Consultar modalidad de atención":"Atiende "+modalidad}</p>
          <p className="pcardcataloginfo">{minprice==="Sin información"?"Consultar valores":"Valores desde $"+formato.format(minprice)}</p>
           
          {/* {listItems ? <div className="catalog__profile_esp">{listItems}</div> : <></>} */}
          <a className="linkcard" href={"/ps/" + href+"?solicitud="+solicitud}>
            <p style={{fontSize:"13px"}}>Ver Perfil</p>
          </a>
        </div>
      </div>
      </div>
    </div>
  );
};
