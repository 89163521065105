
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "./Card.css"; // Asegúrate de importar el CSS que ya tienes

import ModalButton2 from '../../Modals/ModalButton_gal';


const PhotoGallery = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);  // Índice para controlar la imagen actual

  const openModal = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);  // Actualiza el índice cuando se abre el modal
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  // Funciones para navegar entre las imágenes
  const nextImage = () => {
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const prevImage = () => {
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  return (
    <div className="gallery-container">
      <div className="popup-gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img
              src={image}
              alt={`Thumbnail ${index}`}
              onClick={() => openModal(index)}  // Pasa el índice cuando se hace clic
              className="gallery-img"
            />
          </div>
        ))}
      </div>

      {/* Modal con flechas */}
      <ModalButton2 toShow={isModalOpen} onClose={closeModal}>
        <div className="modal-img-container">
          <button className="prev-button" onClick={prevImage}>
            &#10094; {/* Flecha izquierda */}
          </button>
          <img src={selectedImage} alt="Full size" className="modal-img" />
          <button className="next-button" onClick={nextImage}>
            &#10095; {/* Flecha derecha */}
          </button>
        </div>
      </ModalButton2>
    </div>
  );
};


PhotoGallery.propTypes = {
  images: PropTypes.array,
 
};
// import PropTypes from 'prop-types';


export default PhotoGallery;
