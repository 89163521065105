/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import {CROP} from "./coments.js";

import axios from "axios";
import Nophoto from "../img/nonprof.webp";
import "../login/login.css";
import Cookies from "js-cookie";
import Resizer from "react-image-file-resizer";

// import { UserContext } from "../login/login-context";

export function EditarBasicinfo({
  edad: initiaEdad,
  enfoque: initiaEnfoque,
  genero: initiaGenero,
  experiencia: initiaExperiencia,
  minsal: initiaMinsal,
  nacionalidad: initiaNacionalidad,
  imgprof: initiaimg,
}) {
  //   const [nombre, setNombre] = useState("");
  //   const [apellido, setApellido] = useState("");

  const [edad, setEdad] = useState(initiaEdad ? initiaEdad : "Infantil");
  const [enfoque, setEnfoque] = useState(
    initiaEnfoque ? initiaEnfoque : "Psicoanálisis",
  );
  const [experiencia, setExperiencia] = useState(
    initiaExperiencia ? initiaExperiencia : "",
  );
  const [genero, setGenero] = useState(initiaGenero ? initiaGenero : "");
  const [imgprof, setImgprof] = useState(initiaimg ? initiaimg : Nophoto)
  const [minsal, setMinsal] = useState(initiaMinsal ? initiaMinsal : "");
  const [nacionalidad, setNacionalidad] = useState(
    initiaNacionalidad ? initiaNacionalidad : "",
  );

  // eslint-disable-next-line no-unused-vars

  const [isExpanded_edad, setisExpanded_edad] = useState(false);
  const expandir_edad = () => {
    setisExpanded_edad(!isExpanded_edad);
  };

  const [isExpanded_enf, setisExpanded_enf] = useState(false);
  const expandir_enf = () => {
    setisExpanded_enf(!isExpanded_enf);
  };
const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}
  useEffect(() => {
    setEdad(initiaEdad);
  }, [initiaEdad]);

  useEffect(() => {
    setEnfoque(initiaEnfoque);
  }, [initiaEnfoque]);

  useEffect(() => {
    setGenero(initiaGenero);
  }, [initiaGenero]);

  useEffect(() => {
    setExperiencia(initiaExperiencia);
  }, [initiaExperiencia]);
  useEffect(() => {
    setMinsal(initiaMinsal);
  }, [initiaMinsal]);
  useEffect(() => {
    setNacionalidad(initiaNacionalidad);
  }, [initiaNacionalidad]);

  const [edad_array, setEdad_Array] = useState(
    edad ? edad.split(",").slice(0) : [],
  );
  const [enfoque_array, setEnfoque_Array] = useState(
    enfoque ? enfoque.split(",").slice(0) : [],
  );
  // profile_picture

  //CargarlaFOTO
  const [subirfoto, setSubirfoto] = useState(false);

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Redimensionar la imagen
      Resizer.imageFileResizer(
        file,
        1080, // max width
        1080, // max height
        "JPEG", // format
        70, // quality
        0, // rotation
        (uri) => {
          // `uri` es la imagen redimensionada en formato base64
          setImage(uri);
        },
        "base64",
      );
    }
  };

  //Enviar al Servidor
  const update_info = (info, setData, data) => {
    axios
      .post("/api/update_user_info", info, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);

        setData(data);
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  //Arreglo de edad

  const handleChangeEdad = (value) => {
    setEdad_Array((prevOptions) => {
      if (prevOptions.includes(value)) {
        return edad_array.length > 1
          ? prevOptions.filter((opt) => opt !== value)
          : edad_array;
      } else {
        return edad_array.length <= 2 ? [...prevOptions, value] : edad_array;
      }
    });
    console.log(edad_array);
  };

  //Arreglo de enfoque

  const handleChangeEnfoque = (value) => {
    setEnfoque_Array((prevOptions) => {
      if (prevOptions.includes(value)) {
        return enfoque_array.length > 1
          ? prevOptions.filter((opt) => opt !== value)
          : enfoque_array;
      } else {
        return enfoque_array.length <= 3
          ? [...prevOptions, value]
          : enfoque_array;
      }
    });
  };
  const saveData = () => {
    update_info({ minsal_reg_no: minsal }, setMinsal, minsal);

    // update_info({approach: "Psicoanálisis"}, setEnfoque, enfoque);
    //  update_info({title: "Infantil"}, setEdad, edad);
    update_info({ title: edad_array.join(",") }, setEdad, edad);
    update_info({ approach: enfoque_array.join(",") }, setEnfoque, enfoque);
    // update_info({title: "Infantil"}, setEdad, edad);

    // update_info({approach: "Humanista"}, setEnfoque, enfoque);

    update_info({ gender: genero }, setGenero, genero);
    update_info(
      { years_of_experience: experiencia },
      setExperiencia,
      experiencia,
    );
    update_info({ nationality: nacionalidad }, setNacionalidad, nacionalidad);
    if (image) {
      return update_info({ profile_picture: image }, setImage, image);
    }
  };

  // function base64ToUint8Array(base64) {
  //   const binaryString = atob(base64);
  //   const len = binaryString.length;
  //   const bytes = new Uint8Array(len);
  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }
  //   return bytes;
  // }

  // function uint8ArrayToBase64(uint8Array) {
  //   let binaryString = '';
  //   for (let i = 0; i < uint8Array.byteLength; i++) {
  //     binaryString += String.fromCharCode(uint8Array[i]);
  //   }
  //   return btoa(binaryString);
  // }

  // function compressBase64(base64) {
  //   const uint8Array = base64ToUint8Array(base64);
  //   const compressed = pako.deflate(uint8Array);
  //   return uint8ArrayToBase64(compressed);
  // }
  // function decompressBase64(base64) {
  //   const uint8Array = base64ToUint8Array(base64);
  //   const decompressed = pako.inflate(uint8Array);
  //   return uint8ArrayToBase64(decompressed);
  // }

  return (
    <div className="form">
      <div className="modal-header" style={{ height: "20px", padding: "3px" }}>
        {/* <button

          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"

          style={{padding:"10px"}}
        >
          <span aria-hidden="true">&times;</span>
        </button> */}
      </div>
      <form className="editarperfilmodal" onSubmit={(e) => e.preventDefault()}>
        {/* <input className="multicheck"  type="checkbox" value="" id="preference1"/> */}

        <div style={{ position: "relative" }}>
          {/* <img
          className="imghead epimghead"
          href="/"
          src={image ? image : Nophoto} 
          alt="Descripción de la imagen"
          
        /> 
        <i className="fa-solid fa-camera cameraperfil" onClick={handleIconClick}></i>
        <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleImageChange}
      /> */}
          <div
            style={{
              margin: "0 auto",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <CROP imgprof={imgprof} />
          </div>
        </div>

        <article>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Edades a las que atiendes:{" "}
            </p>
            <a onClick={expandir_edad}>
              <i
                className={`fa-solid fa-caret-${isExpanded_edad ? "up" : "down"}`}
                style={{ margin: "2%", fontSize: "22px" }}
              ></i>
            </a>
          </div>

          <div className={`${isExpanded_edad ? "show" : "notshow"}`}>
            <div
              className={
                edad_array.includes("Infantil")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEdad("Infantil")}
            >
              Infantil
            </div>
            <div
              className={
                edad_array.includes("Adolescente")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEdad("Adolescente")}
            >
              Adolescente
            </div>
            <div
              className={
                edad_array.includes("Adulto")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEdad("Adulto")}
            >
              Adultos
            </div>
            <div
              className={
                edad_array.includes("Adulto Mayor")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEdad("Adulto Mayor")}
            >
              Adulto Mayor
            </div>
            <p> {edad} </p>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Tú perspectiva teórica:
            </p>
            <a onClick={expandir_enf}>
              <i
                className={`fa-solid fa-caret-${isExpanded_enf ? "up" : "down"}`}
                style={{ margin: "2%", fontSize: "22px" }}
              ></i>
            </a>
          </div>
          <div className={`${isExpanded_enf ? "show" : "notshow"}`}>
            <div
              className={
                enfoque_array.includes("Psicoanálisis")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEnfoque("Psicoanálisis")}
            >
              Psicoanálisis
            </div>
            <div
              className={
                enfoque_array.includes("Sistémico")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEnfoque("Sistémico")}
            >
              Sistémico
            </div>
            <div
              className={
                enfoque_array.includes("Cognitivo Conductual")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEnfoque("Cognitivo Conductual")}
            >
              Cognitivo Conductual
            </div>
            <div
              className={
                enfoque_array.includes("Humanista")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEnfoque("Humanista")}
            >
              Humanista
            </div>
             <div
              className={
                enfoque_array.includes("Otra perspectiva" || "Integrativo")
                  ? "selected_edit"
                  : "selector-ovalado"
              }
              onClick={() => handleChangeEnfoque("Integrativo")}
            >
              Integrativo
            </div>
            {/* <div className={enfoque_array.includes("Terapia Alternativa")? "selected_edit" : "selector-ovalado"} onClick={() => handleChangeEnfoque("Terapia Alternativa")}>
          Terapia Alternativa
        </div> */}
            <p> {enfoque} </p>
          </div>
          {/* <div className="mb-3">

          <input
            type="text"
            onChange={(e) => setEnfoque(e.target.value)}
            placeholder="Ejemplo: Psicoanálisis, Sistémico"
          />

        </div> */}
          <div className="mb-2"></div>
          <div className="mb-2">
            <p style={{ textAlign: "left", fontSize: "13px" }}>Tu género:</p>
            <select
              className="gender_selector"
              id="gender_selector"
              onChange={(e) => setGenero(e.target.value)}
            >
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
              <option value="Transgenerp">Transgenero</option>
              <option value="No binario">No binario</option>
              <option selected value="Otro">
                Otro
              </option>
            </select>{" "}
          </div>
          <div className="mb-2">
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Años de experiencia:
            </p>

            <input
              id="profile-experience"
              value={experiencia}
              type="text"
              onChange={(e) => setExperiencia(e.target.value)}
              placeholder="Años de experiencia (solo número)"
            />
          </div>

          {/* <input
            type="text"
            onChange={(e) => setGenero(e.target.value)}
            placeholder="Género"
          /> */}

          <div className="mb-3">
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              Registro Super Intendencia de Salud:
            </p>

            <input
              id="profile-minsal"
              type="text"
              value={minsal}
              onChange={(e) => setMinsal(e.target.value)}
              placeholder="Nro Registro Minsal"
            />
            <a style={{textDecoration:"underline", color:"#98c1d8", cursor:"default"}} onClick={()=>openInNewTab("https://rnpi.superdesalud.gob.cl/#")}>Buscar registro</a>
          </div>
          <div className="mb-3">
            <p style={{ textAlign: "left", fontSize: "13px" }}>Nacionalidad:</p>

            <input
              id="profile-nationality"
              type="text"
              value={nacionalidad}
              onChange={(e) => setNacionalidad(e.target.value)}
              placeholder="Nacionalidad"
            />
          </div>

          {/* <div className="mb-3">
          <input
            type="text"
            value="Completar en sección Estudios"
            placeholder="Universidad"
          />
        </div> */}
          <button className="button-login" onClick={saveData}>
            Guardar
          </button>
        </article>
      </form>
    </div>
  );
}
