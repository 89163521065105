import React, { useState } from "react";
import NavBar from "../navbar/navbar";
import "./FAQ.css";
import Contact from "../contact/Contact";
import Fotr from "../Footer/footer";

const Help = () => {
  const [isExpanded_1, setisExpanded_1] = useState(false);

  const expandir_1 = () => {
    setisExpanded_1(!isExpanded_1);
  };
  const [isExpanded_2, setisExpanded_2] = useState(false);

  const expandir_2 = () => {
    setisExpanded_2(!isExpanded_2);
  };
  const [isExpanded_3, setisExpanded_3] = useState(false);

  const expandir_3 = () => {
    setisExpanded_3(!isExpanded_3);
  };
  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />

      <section
        className="container-fluid"
        style={{
          width: "fit-content",
          background: "white",
          height: "100hv",
        }}
      >
        <div className="row row-cols-1 no-gutters">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 FAQ">
            <h1 className="fuenteh2">¿Tienes dudas del proceso?</h1>

            <h2 className="fuenteh2">
              Acá te contamos como buscar profesional
            </h2>

            <article className="fuente2">
              <p>
                Para poder conectarte de forma mucho más precisa, necesitamos
                que nos ayudes siendo lo más específicos posible. Abajo podrás
                encontrar algunos ejemplos y la calidad del match que podrías
                obtener
              </p>
              <p></p>
            </article>
            {/* hola */}

            <div className="accordion acordhelp" id="accordionExample">
              <div className="card helpcard">
                <div className="card-header cardhelp" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      style={{
                        textAlign: "left",
                        padding: "0px",
                        width: "100%",
                      }}
                      onClick={expandir_1}
                    >
                      Muy específico - mejor calidad de match:
                      <i
                        className={`fa-solid fa-caret-${isExpanded_1 ? "up" : "down"}`}
                        style={{
                          margin: "2%",
                          paddingTop: "10px",
                          fontSize: "22px",
                          color: "white",
                        }}
                      ></i>
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <p>
                    {" "}
                    ... Hola, soy mujer tengo 26 años, estoy buscando ayuda
                    profesional, nunca antes me he atendido con un psicólogo,
                    pero he decidido consultar porque hace unas semanas me
                    siento bastante deprimida. Siento que mi vida ha dejado de
                    tener sentido, con el tiempo he dejado de ver a mis amigos y
                    amigas y con suerte tengo fuerzas para levantarme ir a
                    trabajar. El momento en que esto partió no lo sé
                    exactamente, pero cada vez se siente peor. Me gustaría
                    atenderme con una mujer cercana, que pueda escuchar y
                    aconsejar cuando se lo pida, si tiene enfoque de género
                    sería mucho mejor. Me gustaría encontrar la profesional
                    ideal porque mi presupuesto es escaso y no puedo perder
                    tiempo y dinero...
                  </p>
                </div>
              </div>
              <div className="card helpcard">
                <div className="card-header cardhelp" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      style={{
                        textAlign: "left",
                        padding: "0px",
                        width: "100%",
                      }}
                      onClick={expandir_2}
                    >
                      Específico - calidad de match estándar:{" "}
                      <i
                        className={`fa-solid fa-caret-${isExpanded_2 ? "up" : "down"}`}
                        style={{
                          margin: "2%",
                          paddingTop: "10px",
                          fontSize: "22px",
                          color: "white",
                        }}
                      ></i>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      {" "}
                      ... Hola, soy mujer tengo 26 años, estoy buscando
                      psicólogo porque hace unas semanas me siento bastante
                      deprimida. Siento que mi vida ha dejado de tener sentido,
                      con el tiempo he dejado de ver a mis amigos y amigas y con
                      suerte tengo fuerzas para levantarme ir a trabajar. Los
                      últimos días empecé con problemas para dormir. Ya no puedo
                      aguantar esta situación, necesito ayuda...
                    </p>
                  </div>
                </div>
              </div>
              <div className="card helpcard">
                <div className="card-header cardhelp" id="headingThree">
                  <h5 className="mb-0">
                    <button
                      className="btn collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      style={{
                        textAlign: "left",
                        padding: "0px",
                        width: "100%",
                      }}
                      onClick={expandir_3}
                    >
                      Poco específico - calidad de match por debajo del
                      estándar:
                      <i
                        className={`fa-solid fa-caret-${isExpanded_3 ? "up" : "down"}`}
                        style={{
                          margin: "2%",
                          paddingTop: "10px",
                          fontSize: "22px",
                          color: "white",
                        }}
                      ></i>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    Busco psicólogo para tratar mi depresión
                  </div>
                </div>
              </div>
            </div>

            {/* ahola */}
            <div className="mt-4" style={{ textAlign: "center" }}>
              <a href="/?bts=Ok">
                <button className="boton-ovalado">
                  <b>Volver a buscar </b>
                </button>
              </a>
            </div>
            <h5 className="mt-4" style={{ textAlign: "center" }}>
              {" "}
              Si tienes más dudas sobre el proceso, no dudes en contactarnos
            </h5>
          </div>

          <div className="col-lg-3"></div>
        </div>
      </section>
      <Contact />
      <Fotr />
    </div>
  );
};

export default Help;
