
import React  from "react";
import PropTypes from 'prop-types';

import "./Foro.css"
import Nophoto from "../img/nonprof.webp";

// eslint-disable-next-line react/prop-types
const ForoUserCard = ({name,lastname,link,img,enfoque,date}) =>{



    



    return(
        <div className="ForoUserCard" >  
        <div style={{padding:"3px", textAlign:"center"}}>
             <img
                className="img-circular-us-p imgforo"
                src={img ? img : Nophoto}
                alt="Descripción de la imagen"
              />
            </div>
           <div style={{padding:"0px"}}>
         <p className="NameCardForo" style={{margin:"0px"}}>{name && lastname && (  (name.length + lastname.length >21) ?( (name.length>10? name.substr(0,9)+"...":name)+" "+(lastname.length>11? lastname.substr(0,8)+"...":lastname)): (name +" " + lastname))}</p>
             <p className="NameCardForo" style={{ display: "flex",margin:"0px" }}>
                <span>Enfoque: </span>
                <span>{"  " + (enfoque)}</span>
              </p>
                      <p className="NameCardForo" style={{ display: "flex",margin:"0px" }}>
                <span>Fecha: </span>
                <span>{date?date:" "}</span>
              </p>

            <a className="ForoCard" href={link}>ver perfil</a>
            </div>   

        </div>
    );
    
};
ForoUserCard.propTypes = {
  enfoque: PropTypes.string, 
  name:PropTypes.string,
  lastname:PropTypes.string,
  link:PropTypes.string,
  img:PropTypes.string,
   // name debe ser una cadena de texto y es obligatorio
     // age debe ser un número y es obligatorio
};

export default ForoUserCard